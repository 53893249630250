<template>
  <div class="tool-bar">
    <el-select filterable placeholder="请选择节点..." @change="getMajorNodeList">
      <el-option v-for="(option, index) in nodeMajorOptionList" :key="index" :value="option"></el-option>
    </el-select>
    <el-button @click="onClickQuerySingleUniversity" type="primary">学校-学院-专业-研究方向查询</el-button>
  </div>
  <div class="echarts-container" ref="echartRef"></div>
  <!-- 学校层面的弹窗 -->
  <el-dialog v-model="subChartVisible" title="专业-学校-学院-研究方向详情" :show-close="false" fullscreen destroy-on-close
    append-to-body>
    <template v-slot:header>
      <div>
        <el-button @click="subChartVisible = false; singleUniversity = false;" type="primary">返回专业层</el-button>
      </div>
    </template>
    <div v-if="singleUniversity">
      <el-select v-model="singleUniversitySelection" filterable remote :loading="loading" :remote-method="remoteMethod" @change="handdleOnSingleUniversityChange">
        <el-option v-for="(university, index) in universityList" :key="index" :value="university">{{ university }}</el-option>
      </el-select>
    </div>
    <el-tree ref="treeRef" class="filter-tree" :data="universityDetail" />
  </el-dialog>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue';
import * as echarts from 'echarts'

// 引用api
import nodeApi from '@/api/node';
import relationApi from '@/api/relation';
import undergraduateApi from '@/api/undergraduate';

// 引用工具类
import uniq from 'lodash/uniq'
import intersection from 'lodash/intersection'
// 单个学校学院专业研究方向查询
import debounce from 'lodash/debounce';

// 声明echart容器
const echartRef = ref(null);
let chartInstance;

// 声明节点和边
let nodes = [];
let edges = [];

// 初始化容器
onMounted(async () => {
  // 专业选项
  await getUndergraduateMajorNameList();
  // 声明echarts实例
  chartInstance = echarts.init(echartRef.value)
  // 设置监听鼠标点击事件
  chartInstance.on('click', (params) => {
    if (params.dataType === 'node' && params.data.category === 0) {
      openSubChart(params)
    }
  })
  await getMajorOptionNodeList()
})

// 监听页面缩放，自适应高度宽度
window.addEventListener('resize', function () {
  chartInstance.resize();
});

// 图像渲染
const render = () => {
  chartInstance.clear();
  chartInstance.setOption({
    title: {
      text: '本科/研究生专业关系图',
      subtext: '如果研究生和本科专业同名，以研究生专业展示，研究生专业（红色）， 本科专业（蓝色）',
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      }
    },
    series: [
      {
        type: 'graph',
        data: nodes,
        edges,
        categories: [
          { name: '研究生专业', symbolSize: 50, itemStyle: { color: 'red' } },
          { name: '本科专业', symbolSize: 10, itemStyle: { color: 'blue' } }
        ],
        layout: 'force',
        center: ['50%', '50%'],
        zoom: 8,
        animation: true,
        roam: true,
        force: {
          layoutAnimation: false
        },
        labelLayout: {
          hideOverlap: true
        },
        scaleLimit: {
          min: 1,
          max: 10
        },
        emphasis: {
          focus: 'adjacency',
        },
        edgeSymbol: ['none', 'arrow'],
      }
    ]
  });
  chartInstance.hideLoading();
}


// 筛选项目
const nodeMajorOptionList = ref([]);

// 获取专业选项和一级学科大类
const majorNameOptions = ref([]);
const subject = ref([]);
const getUndergraduateMajorNameList = async () => {
  const res = await undergraduateApi.getMajorList()
  majorNameOptions.value = res.data
  subject.value = uniq(res.data.map(item => item.subject))
}

// 查询专业节点选项
const getMajorOptionNodeList = async () => {
  chartInstance.showLoading();
  // 研究生专业目录
  const resMajor = await nodeApi.getMajorList()
  const postgraduateMajorList = resMajor.data.map(node => node.major_name)
  // 本科专业目录
  const resUndergraduateMajor = await undergraduateApi.getMajorList()
  const undergraduateMajorList = resUndergraduateMajor.data.map(node => node.major_name)
  // 专业合并去重
  const majorList = uniq(postgraduateMajorList.concat(undergraduateMajorList))
  // 更新专业之间的关系
  const resRelation = await relationApi.getMajorList(majorList.join(','))
  const edgeMajorList = [];
  resRelation.data.filter(edge => {
    return majorList.includes(edge.source_major_name) && majorList.includes(edge.target_major_name)
  }).forEach(edge => {
    edgeMajorList.push(edge.source_major_name)
  })
  // 去除没有连接关系的节点
  nodeMajorOptionList.value = intersection(majorList, edgeMajorList);
  chartInstance.hideLoading();
}

const getMajorNodeList = async (nodeName) => {
  chartInstance.showLoading();
  // 研究生专业目录
  const resMajor = await nodeApi.getMajorList()
  const postgraduateMajorList = resMajor.data.map(node => node.major_name)
  // 本科专业目录
  const resUndergraduateMajor = await undergraduateApi.getMajorList()
  const undergraduateMajorList = resUndergraduateMajor.data.map(node => node.major_name)
  // 专业合并去重
  const majorList = uniq(postgraduateMajorList.concat(undergraduateMajorList))
  // 更新专业之间的关系
  const resRelation = await relationApi.getMajorList(nodeName)
  const edgeMajorList = [];
  edges = resRelation.data.filter(edge => {
    return majorList.includes(edge.source_major_name) && majorList.includes(edge.target_major_name)
  }).map(edge => {
    edgeMajorList.push(edge.source_major_name)
    edgeMajorList.push(edge.target_major_name)
    return {
      source: edge.source_major_name,
      target: edge.target_major_name,
      value: edge.value,
      lineStyle: {
        width: edge.value < 10 ? edge.value : 10,
      },
    }
  })
  // 去除没有连接关系的节点
  const nodeMajorList = intersection(majorList, edgeMajorList)
  // 渲染节点
  nodes = nodeMajorList.map(node => {
    return {
      id: node,
      name: node,
      symbolSize: 5,
      label: {
        show: true,
        position: 'bottom'
      },
      category: postgraduateMajorList.includes(node) ? 0 : 1,
      nodeType: 'major'
    }
  })
  render()
}

/* 从现在开始是学校层面 */
const treeRef = ref(null)
const filterText = ref('')
const subChartVisible = ref(false)
const universityDetail = ref([])

const openSubChart = async (params) => {
  subChartVisible.value = true;
  setTimeout(async () => {
    // 查询本专业下的节点和关系
    const res = await nodeApi.getUniveristylist(params.data.name)
    universityDetail.value = res.data;
  })
}
watch(filterText, (val) => {
  treeRef.value.filter(val)
})


const singleUniversity = ref(false)
const singleUniversitySelection = ref('')
const universityList = ref([])
const loading = ref(false)
const onClickQuerySingleUniversity = async () => {
  subChartVisible.value = true;
  singleUniversity.value = true;
  universityDetail.value = []
}
const remoteMethod = debounce(val => {
  loading.value = true;
  nodeApi.getSingleUniveristylist(val).then(res => {
    universityList.value = res.data;
  }).finally(() => {
    loading.value = false;
  })
}, 500)
const handdleOnSingleUniversityChange = (val) => {
  nodeApi.getSingleUniveristy(val).then(res => {
    universityDetail.value = res.data;
  })
}
</script>

<style>
.tool-bar {
  display: flex;
  margin-bottom: 5px;
}

.echarts-container {
  width: calc(100vw - 20px);
  height: calc(90vh - 20px);
}

.echarts-sub-container {
  width: calc(100vw - 40px);
  height: calc(100vh);
}
</style>
