import request from "@/utils/request";

export default {
  getMajorList: (major_name) => {
    return request.get('/node/major', {
      params: {
        major_name,
      }
    })
  },
  getUniveristylist: (major_name) => {
    return request.get('/node/university', {
      params: {
        major_name,
      }
    })
  },
  getSingleUniveristylist: (val) => {
    return request.get('/node/university_list', {
      params: {
        university_name: val,
      }
    })
  },
  getSingleUniveristy: (val) => {
    return request.get('/node/university_detail', {
      params: {
        university_name: val,
      }
    })
  }
}