import request from "@/utils/request";

export default {
  getMajorList: (major_name) => {
    return request.get('/undergraduate/major', {
      params: {
        major_name,
      }
    })
  },
}